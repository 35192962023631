/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var isMobile = {
              Android: function() {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
              },
              any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
              }
          };

        // Parallax
        var parallax = function() {
            $(window).stellar();
        };

        // Mobile outside click close offcanvas
        var mobileMenuOutsideClick = function() {

              $(document).click(function (e) {
              var container = $("#offcanvas, .js-nav-toggle");
              if (!container.is(e.target) && container.has(e.target).length === 0) {

                  if ( $('body').hasClass('offcanvas') ) {

                      $('body').removeClass('offcanvas');
                      $('.js-nav-toggle').removeClass('active');
                  }
              }
              });

          };

       // Offcanvas menu
       var offcanvasMenu = function() {

            $('#page').prepend('<div id="offcanvas" />');
            $('#page').prepend('<a href="#" class="js-nav-toggle nav-toggle"><i></i></a>');
            var clone1 = $('.primary-nav > ul').clone();
            $('#offcanvas').append(clone1);


            $('#offcanvas .menu-item-has-children').addClass('offcanvas-has-dropdown');
            $('#offcanvas')
                .find('li')
                .removeClass('menu-item-has-children');

            // Hover dropdown menu on mobile
            $('.offcanvas-has-dropdown').mouseenter(function(){

              $(this)
                    .addClass('active')
                    .find('ul')
                    .slideDown(500, 'easeOutExpo');
            }).mouseleave(function(){

                $(this)
                    .removeClass('active')
                    .find('ul')
                    .slideUp(500, 'easeOutExpo');
            });


            $(window).resize(function(){

                if ( $('body').hasClass('offcanvas') ) {

                    $('body').removeClass('offcanvas');
                    $('.js-nav-toggle').removeClass('active');

                }
            });
        };

        var burgerMenu = function() {

            $('body').on('click', '.js-nav-toggle', function(event){
                var $this = $(this);


                if ( $('body').hasClass('offcanvas') ) {
                    $('body').removeClass('offcanvas');
                } else {
                    $('body').addClass('offcanvas');
                }
                $this.toggleClass('active');
                event.preventDefault();

            });
        };

        var goToTop = function() {

            $('.js-gotop').on('click', function(event){

                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $('html').offset().top
                }, 500);

                return false;
            });

        };

         // Loading page
        var loaderPage = function() {
            $(".loader").fadeOut("slow");
        };

        // Window Scroll
        var windowScroll = function() {
            var lastScrollTop = 0;

            $(window).scroll(function(event){

                var header = $('#header'),
                    scrlTop = $(this).scrollTop();

                if ( scrlTop > 500 && scrlTop <= 2000 ) {
                    header.addClass('navbar-fixed-top animated slideInDown');
                } else if ( scrlTop <= 500) {
                    if ( header.hasClass('navbar-fixed-top') ) {
                        header.addClass('navbar-fixed-top animated slideOutUp');
                        setTimeout(function(){
                            header.removeClass('navbar-fixed-top animated slideInDown slideOutUp');
                        }, 100 );
                    }
                }

            });
        };

        // Animate content on scroll
        var contentWayPoint = function() {
          var i = 0;
          $('.animate-box').waypoint( function( direction ) {

              if( direction === 'down' && !$(this.element).hasClass('animated') ) {

                  i++;

                  $(this.element).addClass('item-animate');
                  setTimeout(function(){

                      $('body .animate-box.item-animate').each(function(k){
                          var el = $(this);
                          setTimeout( function () {
                              var effect = el.data('animate-effect');
                              if ( effect === 'fadeIn') {
                                  el.addClass('fadeIn animated');
                              } else if ( effect === 'fadeInLeft') {
                                  el.addClass('fadeInLeft animated');
                              } else if ( effect === 'fadeInRight') {
                                  el.addClass('fadeInRight animated');
                              } else {
                                  el.addClass('fadeInUp animated');
                              }

                              el.removeClass('item-animate');
                          },  k * 200, 'easeInOutExpo' );
                      });

                  }, 100);

              }

          } , { offset: '85%' } );
      };

      var selectToJs = function() {
        $('select').selectize();
      };


     var initOwlCarousel = function() {

        var $verticalCarousel = $('.vertical-carousel');
        $verticalCarousel.owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            dots: false,
            autoplay: true,
            mouseDrag: false,
            smartSpeed: 500,
            animateOut: 'fadeOut',
            navText: [ '<i class="ion-chevron-left"></i>','<i class="ion-chevron-right"></i>']
        });

        var $onTopCarousel = $('.on-top-carousel');
        $onTopCarousel.owlCarousel({
            items: 1,
            loop: true,
            nav: false,
            dots: false,
            autoplay: true,
            mouseDrag: false,
            smartSpeed: 600
        });
     };

     var initAnimation = function() {
       var scene = new ScrollMagic.Scene({tweenChanges: true});
     };


    var initSmoothScroll = function() {
        var scroll = new SmoothScroll('a[href*="#"]', {
          easing: 'easeInOutQuint'
        });
    };


    // init Google map ACF
    initMap($);



      // Document on load.
      $(function(){
          parallax();
          selectToJs();
          mobileMenuOutsideClick();
          offcanvasMenu();
          burgerMenu();
          contentWayPoint();
          windowScroll();
          goToTop();
          initOwlCarousel();
          initAnimation();
          initSmoothScroll();
          loaderPage();
      });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
